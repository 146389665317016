import { useCallback, useEffect, useRef, useState } from "react";
import styles from "./lesson.module.scss";
import LessonNavigationBar from "@/components/lessonNavigation/LessonNavigationBar";
import QuestionForm from "@/components/sections/questionForm/QuestionForm";
import Media from "@/components/sections/media/Media";
import Book from "@/components/sections/book/Book";
import { CurrentSection, LessonData, Navigation, Section, SectionsList, setLessonCompleteFunction, setSectionCompleteFunction } from "@/Interfaces";
import ErrorPage from "../error/ErrorPage";
import { LoaderFunctionArgs, Params, redirect, useLoaderData, useOutletContext, useRouteLoaderData, useSubmit } from "react-router-dom";
import { abortLesson, getSectionData, getSections, setLessonComplete, setSectionComplete as setSectionCompleteReq } from "@/utils/waApiClient";
import { AudioProvider } from "@/components/audioContext/AudioContext";
import { getLocale } from "@/locales/locales";
interface LessonContent {
    [key: number]: JSX.Element;
};
interface Args extends LoaderFunctionArgs {
    params: Params<string>;
}

// Get lesson sections
export const lessonLoader = async ({ params }: Args) => {
    const { completionId } = params;

    if (!completionId) throw new Error();

    const response = await getSections({ completionId });
    return response.data.sections;
};

const Lesson = () => {
    const [canContinue, setCanContinue] = useState<boolean>(false);
    const [sectionsList, setSectionsList] = useState<SectionsList | null>(null);
    const [currentSection, setCurrentSection] = useState<CurrentSection>({});
    const [navigation, setNavigation] = useState<Navigation>({});
    const [feedback, setFeedback] = useState<string | null>(null);
    const [progress, setProgress] = useState(0);
    const [callAnswerSaving, setCallAnswerSaving] = useState<boolean>(false);
    const lessonData = useRouteLoaderData('lesson') as LessonData;
    const sections = useLoaderData() as Section[];
    const submit = useSubmit();
    const lang = useOutletContext();
    const timeout = useRef<ReturnType<typeof setTimeout>>();
    const completionId = sessionStorage.getItem("COMPLETION_ID") ?? '';

    const handleFeedback = useCallback(
        (feedback: string | null) => {
            setFeedback(feedback);
            if (timeout.current) clearTimeout(timeout.current);

            timeout.current = setTimeout(() => {
                setFeedback(null);
            }, 10000);
        },
        [feedback],
    );


    const LESSON_CONTENT: LessonContent = {
        0: <ErrorPage />,
        1: <QuestionForm
            currentSection={currentSection}
            navigation={navigation}
            setCanContinue={setCanContinue}
            feedback={feedback}
            setFeedback={handleFeedback}
            completionId={completionId}
            callAnswerSaving={callAnswerSaving}
            setCallAnswerSaving={setCallAnswerSaving}
        />,
        2: <Media
            currentSection={currentSection}
            navigation={navigation}
            setCanContinue={setCanContinue}
        />,
        // 3: <MediaGroup
        //     currentSection={currentSection}
        //     setCurrentSection={setCurrentSection}
        //     navigation={navigation}
        // />,
        3: <Book
            currentSection={currentSection}
            navigation={navigation}
            setCanContinue={setCanContinue}
            completionId={completionId}
        />,
    };

    useEffect(() => {
        setSectionsList({
            sections: sections,
            sectionsLastIndex: sections.length - 1,
            sectionsFirstIndex: 0
        });
    }, [sections]);

    // Hook for fetching section data by section ID
    useEffect(() => {
        let subscribed: boolean = true;
        const { section } = currentSection;
        if (section) {
            (async () => {
                try {
                    if (!subscribed) return;
                    const url = section.url;
                    const response = await getSectionData({ url });
                    if (response.status !== "success") throw new Error(response.message);

                    setCurrentSection({
                        ...currentSection,
                        sectionData: response.data.section || response.data.book,
                    });

                } catch (error) {
                    console.error(error);
                }
            })();

            return () => {
                subscribed = false;
            };
        }
    }, [currentSection.section?.orderingNumber, lang]);

    // Calculate progress
    useEffect(() => {
        const { levelExerciseType } = lessonData;
        switch (levelExerciseType) {
            case 3:
                const { sectionData } = currentSection;
                const { childSectionIndex } = navigation;

                if (childSectionIndex && sectionData?.questionCount) {
                    setProgress(Math.round((childSectionIndex?.current + 1) / sectionData.questionCount * 100));
                }
                break;
            default:
                if (sectionsList?.sections) {
                    const { sections } = sectionsList;

                    let completed = 0;

                    for (let index = 0; index < sections.length; index++) {
                        const element = sections[index];
                        if (element.completedAt) {
                            completed += 1;
                        }
                    }

                    setProgress(Math.round(completed / sections.length * 100));
                }
                break;
        }
    }, [sectionsList?.sections, navigation.childSectionIndex?.current]);

    const setSectionComplete: setSectionCompleteFunction = () => {
        const { section } = currentSection;

        if (section) {
            submit(
                {
                    sectionNumber: section.orderingNumber,
                    completionId: completionId,
                    intent: "completeSection"
                },
                {
                    method: "post",
                    encType: "application/x-www-form-urlencoded",
                }
            );
        }
    };

    const setLessonComplete: setLessonCompleteFunction = () => {
        submit(
            {
                lessonId: lessonData.id,
                completionId: completionId,
                intent: "completeLesson"
            },
            {
                method: "post",
                encType: "application/x-www-form-urlencoded",
                replace: true
            }
        );
    };

    const abortLesson = () => {
        submit(
            {
                lessonId: lessonData.id,
                completionId: completionId,
                intent: "abortLesson"
            },
            {
                method: "post",
                encType: "application/x-www-form-urlencoded",
            }
        );
    };

    const saveAnswer = () => {
        setCallAnswerSaving(true);
    }

    return (
        <div id="eLesson" className={styles.mainContainer}>
            <div>
                <div>
                    <div className={styles.elessonFormContainer}>

                        {/* TODO: Last section notification component */}
                        {/* <div id="lastSectionNotification" className="ole-notification-container my-1" hidden>
                            <div className="ole-notification-green d-flex gap-2 align-items-center px-2" role="alert">
                                <div>
                                    <svg className="fs-info" width="24px" height="24px"></svg>
                                </div>
                                <div className="d-flex flex-wrap">
                                    <span className="pe-1"></span>
                                    <b></b>
                                </div>
                            </div>
                        </div> */}

                        <AudioProvider>
                            <div className={styles.contentWrapper}>
                                <div id="form-container">
                                    {currentSection?.section && LESSON_CONTENT[currentSection.section?.type ?? 0]}
                                </div>
                            </div>
                        </AudioProvider>

                        {sectionsList?.sections &&
                            <LessonNavigationBar
                                sectionsList={sectionsList}
                                currentSection={currentSection}
                                setCurrentSection={setCurrentSection}
                                navigation={navigation}
                                setNavigation={setNavigation}
                                canContinue={canContinue}
                                setSectionComplete={setSectionComplete}
                                setLessonComplete={setLessonComplete}
                                abortLesson={abortLesson}
                                lessonType={lessonData.levelExerciseType ?? 1}
                                progress={progress}
                                setFeedback={handleFeedback}
                                saveAnswer={saveAnswer}
                            />}

                    </div>
                </div>
            </div>
        </div>
    );
};

export const lessonActions = async ({ request }: { request: Request; }) => {
    const form = await request.formData();
    const intent = form.get("intent");
    const courseId = sessionStorage.getItem('COURSE_ID');
    const drivingLicense = sessionStorage.getItem("DRIVING_LICENSE");
    const courseGroupId = sessionStorage.getItem('COURSE_GROUP_ID');
    const completionId = sessionStorage.getItem('COMPLETION_ID');
    const lang = getLocale();


    if (intent === "completeLesson") {
        const response = await setLessonComplete({ form });
        if (response.status !== "success") throw new Error(response.message);
        sessionStorage.setItem('SHOW_CONGRATULATION_MODAL', JSON.stringify({ 'show': response.data.showCongratulationModal, 'type': response.data.lessonLevelExerciseType, 'firstName': response.data.userFirstName }));

        return redirect(`/lesson/${completionId}/completed?lang=${lang}`);
    }

    if (intent === "completeSection") {
        const responseData: { [key: string]: any; } = {};
        await setSectionCompleteReq({ form });
        responseData.ok = form.get("sectionNumber");
        return responseData;
    }

    if (intent === "abortLesson") {
        await abortLesson({ form });
        return redirect(`/portal/${courseId}/${drivingLicense}/group/${courseGroupId}?lang=${lang}`);
    }
};

export default Lesson;