import Markdown from "marked-react";
import styles from "./book.module.scss";
import cn from "classnames";
import { BookPart } from "@/Interfaces";
import VideoContainer from "@/components/video/videoContainer";
import ImageContainer from "@/components/image/ImageContainer";
interface ParagraphsProps {
    part: BookPart;
}

const Paragraphs: React.FC<ParagraphsProps> = ({ part }) => {

    return (
        <>
            <div className={cn(styles.paragraph, styles.noMargin)}>
                <Markdown>{part.title}</Markdown>
            </div>
            {part.paragraphs.map((paragraph, index) => [
                paragraph.text &&
                <div key={index} className={styles.paragraph}>
                    <Markdown>{paragraph.text}</Markdown>
                </div>,
                paragraph.video &&
                <VideoContainer key={index} src={paragraph.video} fileLocationType={4}/>,
                paragraph.image &&
                <ImageContainer key={index} src={paragraph.image} zoom/>

            ])}
        </>
    );
};

export default Paragraphs;