import { Link, LoaderFunctionArgs, Params, useLoaderData } from "react-router-dom";
import styles from "@/views/lesson/lesson.module.scss";
import { useEffect, useState } from "react";
import { CurrentSection, SectionData } from "@/Interfaces";
import Book from "@/components/sections/book/Book";
import { getMaterialBook } from "@/utils/waApiClient";
import LessonNavigationBar from "@/components/lessonNavigation/LessonNavigationBar";
import { Navigation } from "@sentry/react/build/types/types";
import { AudioProvider } from "@/components/audioContext/AudioContext";
import cn from 'classnames';
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { rootLoader } from "../root/Root";

interface Args extends LoaderFunctionArgs {
    params: Params<string>;
}

export const materialLoader = async ({ params }: Args) => {
    await rootLoader();
    const { bookId } = params;

    if (!bookId) throw new Error();
    const response = await getMaterialBook({ bookId });

    return response.data.book;
};

const Material = () => {
    const [currentSection, setCurrentSection] = useState<CurrentSection>({});
    const [navigation, setNavigation] = useState<Navigation>({});
    const materialData = useLoaderData() as SectionData;
    const returnUrl = import.meta.env.VITE_WEBAUTO_LOGIN;
    const { t } = useTranslation();

    useEffect(() => {
        setCurrentSection({
            ...currentSection,
            sectionData: materialData // TODO stuff here
        });
    }, []);

    return (
        <>
            <header className={cn(styles.header)}>
                <Link to={Cookies.get('returnUrl') ?? returnUrl} className={styles.return}>
                    <span className={cn(styles.mobile, "material-symbols-rounded q_white")}>
                        chevron_left
                    </span>
                    <div className={cn(styles.desktop)}>
                        <div >
                            <span className="material-symbols-rounded">
                                keyboard_return
                            </span>
                        </div>
                        <span>{t('goBack')}</span>
                    </div>
                </Link>
            </header>
            <div id="eLesson" className={styles.mainContainer}>
                <div>
                    <div>
                        <div className={styles.elessonFormContainer}>
                            <AudioProvider>
                                <div className={styles.contentWrapper}>
                                    <div id="form-container">
                                        <Book
                                            currentSection={currentSection}
                                            navigation={navigation}
                                            completionId={"0"}
                                        />
                                    </div>
                                </div>
                            </AudioProvider>
                            <LessonNavigationBar
                                currentSection={currentSection}
                                setCurrentSection={setCurrentSection}
                                navigation={navigation}
                                setNavigation={setNavigation}
                                canContinue={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Material;;