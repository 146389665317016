import { useCallback, useEffect, useState } from "react";
import styles from "./questionForm.module.scss";
import { Answer, CurrentSection, Navigation, Question, SummaryData, saveAnswerFunction } from "@/Interfaces";
import TextQuestion from "./question_types/TextQuestion";
import CheckboxQuestion from "./question_types/CheckboxQuestion";
import RadioQuestion from "./question_types/RadioQuestion";
import Instruction from "./question_types/Instruction";
import Markdown from "marked-react";
import cn from "classnames";
import ReaderButton from "@/components/readerButton/ReaderButton";
import { useTranslation } from "react-i18next";
import NumberQuestion from "./question_types/NumberQuestion";
import { getQuestion, getQuestionFormSummary, saveAnswer as saveAnswerReq } from "@/utils/waApiClient";
import ImageContainer from "@/components/image/ImageContainer";
import { TASK_MEDIA_FILE_PATH } from "@/constants";
import VideoContainer from "@/components/video/videoContainer";
import SummaryDropdown from "@/components/summaryDropdown/SummaryDropdown";
import { useOutletContext } from "react-router-dom";

interface QuestionFormProps {
    currentSection: CurrentSection,
    navigation: Navigation,
    setCanContinue: React.Dispatch<React.SetStateAction<boolean>>,
    feedback: string | null,
    setFeedback: (feedback: string | null) => void,
    completionId: string;
    callAnswerSaving: boolean;
    setCallAnswerSaving: React.Dispatch<React.SetStateAction<boolean>>;
}

interface QuestionType {
    [key: number]: JSX.Element;
};

const QuestionForm: React.FC<QuestionFormProps> = ({ currentSection, navigation, setCanContinue, feedback, setFeedback, completionId, callAnswerSaving, setCallAnswerSaving }) => {
    const [question, setQuestion] = useState<Question | null>(null);
    const [answer, setAnswer] = useState<Answer | null>(null);
    const [answerArr, setAnswerArr] = useState<string[]>([]);
    const [questionFormSummary, setQuestionFormSummary] = useState<SummaryData | null>(null);
    const [lessonFeedback, setLessonFeedback] = useState('');
    const lang = useOutletContext();    

    // Callback function to toggle whether to show feedback or answer button
    const addAnswer = useCallback((arr: string[]) => {
        setFeedback(null);
        setAnswerArr(arr);
    }, [answerArr]);

    const { t } = useTranslation();

    const QUESTION_TYPE: QuestionType = {
        1: <TextQuestion question={question} answer={answer} answerArr={answerArr} setAnswerArr={addAnswer} />,
        2: <NumberQuestion question={question} answer={answer} answerArr={answerArr} setAnswerArr={addAnswer} />,
        3: <CheckboxQuestion question={question} answer={answer} answerArr={answerArr} setAnswerArr={addAnswer} />,
        4: <RadioQuestion question={question} answer={answer} answerArr={answerArr} setAnswerArr={addAnswer} />,
        6: <Instruction question={question} />
    };

    useEffect(() => {
        let subscribed = true;
        const { sectionData } = currentSection;
        const { childSectionIndex } = navigation;

        if (showQuestonFormSummary()) {
            (async () => {
                if (!subscribed) return;
                const questionFormId = sectionData?.id;

                if (!questionFormId) throw new Error("questionFormId not found");
                const response = await getQuestionFormSummary({ questionFormId, completionId });

                if (response.status !== "success") throw new Error("Bad request");
                setCanContinue(true);
                setQuestionFormSummary(response.data);
            })();

            return;
        }

        if (sectionData && isQuestionDataSet() && childSectionIndex && !showQuestonFormSummary()) {
            (async () => {
                setQuestionFormSummary(null);
                try {
                    if (!subscribed) return;
                    const questionsArray = sectionData.questions!;
                    const questionId = questionsArray[childSectionIndex.current];
                    const response = await getQuestion({ questionId, completionId });
                    const mustAnswerCorrectlyToContinue = sectionData?.mustAnswerCorrectlyToQuestionToContinue;

                    if (response.status !== "success") throw new Error("Bad request");

                    setQuestion(response.data.question);

                    // Correct answer is required and user has given the incorrect answer.
                    if (mustAnswerCorrectlyToContinue && response.data.answer && response.data.answer.isCorrect === false) {
                        setAnswer(response.data.answer);
                        setCanContinue(false);
                        return;
                    }

                    // Correct answer is not required and user has given any answer.
                    if (response.data.answer) {
                        setAnswer(response.data.answer);
                        setCanContinue(true);
                        return;
                    }

                    // User has not answered yet.
                    setAnswer(null);
                    setCanContinue(false);

                } catch (error) {
                    console.error(error);
                }
            })();

            return () => {
                subscribed = false;
                setQuestion(null);
                setFeedback(null);
                setAnswerArr([]);
                setQuestionFormSummary(null);
            };
        }
    }, [navigation.childSectionIndex, lang]);

    useEffect(() => {
        const { sectionData } = currentSection;

        if (question && answer && sectionData?.mustAnswerCorrectlyToQuestionToContinue) {
            const { feedback = t('answered') } = question;
            const { feedbackWrong = t('incorrect') } = question;

            if (feedback && feedbackWrong) {
                if (answer.isCorrect) {
                    setFeedback(feedback);
                } else {
                    setFeedback(feedbackWrong);
                }
            }
        }
    }, [answer]);

    useEffect(() => {
        if (questionFormSummary) {
            if (questionFormSummary.completionPercentage === 100) { setLessonFeedback(t('sectionFeedback.perfectCompletion')); }
            if (questionFormSummary.completionPercentage <= 99) { setLessonFeedback(t('sectionFeedback.wowAmazing')); }
            if (questionFormSummary.completionPercentage <= 90) { setLessonFeedback(t('sectionFeedback.wellDone')); }
            if (questionFormSummary.completionPercentage <= 70) { setLessonFeedback(t('sectionFeedback.decentCompletion')); }
            if (questionFormSummary.completionPercentage <= 50) { setLessonFeedback(t('sectionFeedback.oops')); }
            if (questionFormSummary.completionPercentage <= 10) { setLessonFeedback(t('sectionFeedback.morePractice')); }
        }

        return () => {
            setLessonFeedback('');
        };
    }, [questionFormSummary]);

    useEffect(() => {
        const saveAnswer: saveAnswerFunction = async () => {
            const answers = answerArr;
            if (answers.length <= 0) {
                setFeedback(t('selectAnswerChoice'));
                return;
            }
            try {
                if (question && answers) {
                    const form = new FormData();

                    form.append("questionId", question.id);
                    form.append("completionId", completionId);

                    for (let i = 0; i < answers.length; i++) {
                        form.append("answer[]", answers[i]);
                    }

                    const response = await saveAnswerReq({ form });
                    if (response.status !== "success") throw new Error(response.message);

                    const { isCorrect } = response.data;
                    const { questionId } = response.data;
                    const { canContinue } = response.data;

                    if (canContinue) {
                        setCanContinue(true);
                    }

                    setAnswer({
                        answer: answers,
                        isCorrect: isCorrect,
                        questionId: questionId,
                    });

                }

            } catch (error) {
                console.error(error);
            }
        };

        if (callAnswerSaving) {
            saveAnswer();
        }

        return () => {
            setCallAnswerSaving(false);
        };
    }, [callAnswerSaving]);



    const isQuestionDataSet = (): boolean => {
        return !!currentSection.sectionData?.questions;
    };

    const showQuestonFormSummary = () => {
        return currentSection.sectionData?.questionCount === navigation.childSectionIndex?.current && currentSection.sectionData?.showQuestionAnswerFeedback;
    };

    return (
        <>
            {questionFormSummary ?
                <div className={styles.summaryContainer}>
                    <div className={styles.contentWrapper}>
                        <div className={styles.header}>
                            <h4>{t('sectionCompleted')}</h4>
                        </div>
                        <div className={styles.body}>
                            <div className={styles.summary}>
                                <b>{t('correctAnswers')}:</b>
                                <h1>{questionFormSummary.correctAnswers}/{questionFormSummary.questionCount}</h1>
                                <b>{lessonFeedback}</b>
                            </div>
                        </div>
                        <div>
                            <SummaryDropdown title={t('questionSummary')} questions={questionFormSummary.questions} totalCount={questionFormSummary.questionCount} />
                        </div>
                    </div>
                </div>
                : currentSection.sectionData && <div className="container-fluid">
                    <div className={styles.title}>
                        <h6>{currentSection.sectionData.name} {navigation.childSectionIndex && navigation.childSectionIndex.current + 1}/{currentSection.sectionData.questionCount}</h6>
                    </div>
                    {question &&
                        <>
                            <div className={styles.questionContainer}>
                                <div className={styles.question}>
                                    <div>
                                        <Markdown>{question.text}</Markdown>
                                    </div>
                                    {question.file &&
                                        <>
                                            {
                                                question.file.type === 1 ? <ImageContainer src={`${TASK_MEDIA_FILE_PATH[question.file.fileLocation]}${question.file.fileName}`} questionImage zoom />
                                                    : question.file.type === 2 && <VideoContainer fileLocationType={question.file.fileLocation} src={question.file.fileUrl} />
                                            }
                                        </>
                                    }
                                </div>
                                {question.text && <ReaderButton text={question.text} />}
                            </div>
                            {QUESTION_TYPE[question.type]}
                            {feedback &&
                                <div id="question-feedback" className={styles.feedbackContainer}>
                                    <ReaderButton text={feedback} />
                                    <div className={cn(styles.feedbackText, {
                                        [styles.correct]: answer?.isCorrect,
                                        [styles.incorrect]: !answer?.isCorrect,
                                        [styles.notAnswered]: !answer
                                    })}>
                                        <div>
                                            <Markdown>{feedback}</Markdown>
                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                    }
                </div>}
        </>
    );
};

export default QuestionForm; 