import styles from "./root.module.scss";
import { Outlet, useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { availableLanguages, DEFAULT_LANGUAGE, getLocale, setLocale } from "@/locales/locales";
import MainMenu from "@/components/mainMenu/MainMenu";
import { changeLanguageFunction } from "@/Interfaces";

export const rootLoader = async (): Promise<any> => {
    try {

        const params = new URLSearchParams(document.location.search);
        const lang = params.get("lang") || getLocale();

        await setLocale(lang);
    } catch (error) {
        throw new Error(); // TODO: Error messages
    }

    return null;
};


const Root = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [supportedlanguages, setSupportedLanguages] = useState<string[] | null>(null);
    const [lang, setLang] = useState<string>();
    const [selectedLocale, setSelectedLocale] = useState<string>();
    const ref = useRef(null) as React.RefObject<HTMLDivElement>;
    const locale = useCallback<changeLanguageFunction>(async (lang: string) => {
        await setLocale(lang);

        setSearchParams((prev) => {
            prev.set('lang', lang);
            return prev;
        }, { replace: true });
        setSelectedLocale(lang);
    }, [selectedLocale]);

    useEffect(() => {
        const initLanguagesAndLocale = async () => {
            const supportedLanguages = availableLanguages.map(item => item.key);
            setSupportedLanguages(supportedLanguages);

            const locale = getLocale();

            if (supportedLanguages.includes(locale)) {
                setLocale(locale);
                setLang(locale);
            } else {
                setLocale(DEFAULT_LANGUAGE);
                setLang(DEFAULT_LANGUAGE);
            }
        };

        initLanguagesAndLocale();
    }, [availableLanguages, selectedLocale]);

    return (
        <>
            <div className={styles.container}>
                <button type="button" data-bs-toggle="offcanvas" data-bs-target="#mainMenu" aria-controls="mainMenu">
                    <span className="material-symbols-rounded q_white">
                        menu
                    </span>
                </button>
            </div>
            <MainMenu supportedlanguages={supportedlanguages} refProp={ref} locale={locale} lang={lang} />
            <Outlet context={{ lang, setSupportedLanguages }} />
        </>
    );
};


export default Root;