import { useEffect, useState } from "react";
import Part from "./Part";
import styles from "./book.module.scss";
import { BookPart, ContextType, CurrentSection, Navigation } from "@/Interfaces";
import { getBookPart, getMaterialBookPage } from "@/utils/waApiClient";
import { useLocation, useOutletContext } from "react-router-dom";

interface BookProps {
    currentSection: CurrentSection,
    navigation: Navigation,
    setCanContinue?: React.Dispatch<React.SetStateAction<boolean>>,
    completionId: string;
}

const Book: React.FC<BookProps> = ({ currentSection, navigation, setCanContinue, completionId }) => {
    const [part, setPart] = useState<BookPart | null>(null);
    const { pathname } = useLocation();
    const { lang } = useOutletContext<ContextType>();

    useEffect(() => {
        let subscribed = true;
        const { sectionData } = currentSection;
        const { childSectionIndex } = navigation;
        setCanContinue && setCanContinue(true);

        if (sectionData && childSectionIndex && isPartsSet()) {
            (async () => {
                try {
                    if (!subscribed) return;
                    const { id } = sectionData;
                    const { pages } = sectionData;
                    let response;

                    if (!pages) throw new Error();
                    if (pathname.includes('lesson')) response = await getBookPart({ bookId: id, pageId: pages[childSectionIndex.current], completionId: completionId });
                    if (pathname.includes('material')) response = await getMaterialBookPage({ bookId: id, pageId: pages[childSectionIndex.current] });
                    if (response.status !== "success") throw new Error("Bad request");

                    setPart(response.data.page);
                }
                catch (error) {
                    console.error(error);
                }
            })();
        }

        return () => {
            subscribed = false;
            setPart(null);
        };
    }, [navigation.childSectionIndex, lang]);

    const isPartsSet = (): boolean => {
        return !!currentSection.sectionData?.pages;
    };

    return (
        currentSection.sectionData &&
        <>
            <div className={styles.title}>
                <h6>{currentSection.sectionData.name} {navigation.childSectionIndex && navigation.childSectionIndex.current + 1}/{currentSection.sectionData.pagesCount}</h6>
            </div>
            {part && <Part part={part} />}
        </ >
    );
};
export default Book;