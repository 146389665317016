import { useEffect, useState } from "react";
import { LoaderFunctionArgs, Outlet, Params, useOutletContext } from "react-router-dom";
import styles from "@/views/portal/portal.module.scss";
import { getLocale } from "@/locales/locales";
import { getLessonGroups, getPortalConfiguration } from "@/utils/waApiClient";
import { ContextType } from "@/Interfaces";
import { useTranslation } from "react-i18next";
import useInfoDialog from "@/hooks/useInfoDialog";
import usePortalConfiguration from "@/hooks/usePortalConfiguration";
import DialogModal from "@/components/modal/DialogModal";
import { rootLoader } from "@/views/root/Root";

interface Args extends LoaderFunctionArgs {
    params: Params<string>;
}

export const portalRootLoader = async ({ params }: Args): Promise<any> => {
    await rootLoader();
    const { courseId } = params;
    const { drivingLicense } = params;

    if (!courseId || !drivingLicense) throw new Error();
    sessionStorage.setItem("COURSE_ID", courseId);
    sessionStorage.setItem("DRIVING_LICENSE", drivingLicense);

    const response = await getLessonGroups({ courseId, drivingLicense });
    const config = await getPortalConfiguration({ courseId, drivingLicense });
    sessionStorage.setItem("PORTAL_CONFIG", JSON.stringify(config.data));

    return response.data;
};


const PortalRoot = () => {
    const [showFirstTimeInfo, setShowFirstTimeInfo] = useState<boolean | null>(null);
    const { t } = useTranslation();
    const getInfoDialod = useInfoDialog();
    const getPortalConfig = usePortalConfiguration();
    const config = getPortalConfig();
    const { lang } = useOutletContext<ContextType>();

    useEffect(() => {
        if (config?.configuration.areIntroductionTextsEnabled && ['portal', 'lesson'].some(str => location.pathname.includes(str))) {
            setShowFirstTimeInfo(!localStorage.getItem('SHOW_FIRST_TIME_INFO') ? true : false);
        }
    }, []);

    return (
        <>
            <Outlet context={lang} />
            {showFirstTimeInfo &&
                <DialogModal label={t('info')} title={t('infoDialogTitle')} parts={getInfoDialod()} autoShow closeModalCallBack={() => localStorage.setItem('SHOW_FIRST_TIME_INFO', 'false')} />
            }
            <div className={styles.footer}>
                <div>
                    <img src="\q_logo_webauto-vertical_1.svg" alt="Webauto" width="140px" height="81px" />
                    <span>Copyright 2024 Datadrivers Oy</span>
                </div>
            </div>
        </>
    );
};

export default PortalRoot;